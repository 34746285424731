import { useState, createContext, type PropsWithChildren } from "react";

interface GoogleSessionTokenContextData {
  token?: google.maps.places.AutocompleteSessionToken;
  setToken?: (token: google.maps.places.AutocompleteSessionToken) => void;
}

export const GoogleSessionTokenContext =
  createContext<GoogleSessionTokenContextData>({});

export const GoogleSessionTokenProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [token, setToken] =
    useState<google.maps.places.AutocompleteSessionToken>();

  return (
    <GoogleSessionTokenContext.Provider value={{ token, setToken }}>
      {children}
    </GoogleSessionTokenContext.Provider>
  );
};

import { useRef, useState, useEffect, useContext, createContext } from "react";

import { Loader } from "@googlemaps/js-api-loader";

export const GoogleApiKeyContext = createContext<string | undefined>(undefined);

export const GoogleApiKeyProvider = GoogleApiKeyContext.Provider;

const options: google.maps.places.AutocompleteOptions = {
  componentRestrictions: { country: "fr" },
  fields: ["formatted_address"],
  types: ["address"],
};
function useGooglePlace() {
  const ref = useRef<HTMLInputElement>();
  const [data, setData] = useState<string | null>(null);
  const apiKey = useContext(GoogleApiKeyContext);

  useEffect(() => {
    const { current } = ref;
    if (current === undefined || !apiKey) {
      return;
    }
    const p = new Loader({ apiKey, libraries: ["places"] })
      .load()
      .then(({ maps }) => new maps.places.Autocomplete(current, options))
      .then(({ addListener, getPlace }) =>
        addListener("place_changed", () =>
          setData(getPlace().formatted_address || null),
        ),
      );
    return () => {
      p.then(({ remove }) => remove());
    };
  }, [ref.current, apiKey]);
  return {
    inputRef: ref,
    defaultValue: data,
  };
}

export default useGooglePlace;
